<template>
  <b-container>
    <b-row><h1>Admin-Dashboard</h1></b-row>
    <b-row>
      <p :class="{ online: online, offline: !online }">{{online ? 'Bot online' : 'Bot offline'}}</p>
    </b-row>
    <b-row>
      <b-col>
        <h3>Bot Controls</h3>
      </b-col>
      <b-col>
        <h3>Dashboard Controls</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button @click="updateBot">UpdateBot</b-button>
      </b-col>
      <b-col>
        <b-button
          v-clipboard:copy="$store.state.authDetails.access_token"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >Copy AT</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Admin',
  data() {
    return {
      online: false,
    };
  },
  mounted() {
    this.botStatus();
    setInterval(() => {
      this.botStatus();
    }, 1000);
  },
  methods: {
    botStatus() {
      this.axios.get('/servicestate')
        .then((res) => {
          if (res.status == 200) {
            this.online = true;
          } else {
            this.online = false;
          }
        })
        .catch(() => {
          this.online = false;
        });
    },
    updateBot() {
      this.$analytics.logEvent('bot_update');
      this.axios.get('/root/updateBot', {
          headers: {
            Authorization: `Bearer ${this.$store.state.authDetails.access_token}`
          }
        })
        .then((res) => {
          if (res.status == 200) {
            this.toast('Erfolgreich', 'Bot updated & startet gleich neu', 'success')
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // this.$store.commit('SET_TOKEN_EXPIRED');
          }
        });
    },
    onCopy () {
      this.toast('Success', 'copied access token to clipboard', 'success')
    },
    onError (e) {
      this.toast('Error', 'error copying access token', 'danger')
      console.log(e);
    }
  }
}
</script>

<style>
.online:before {
  content: ' \25CF';
  margin-right: 0.5rem;
  color: #44af4d;
}

.offline:before {
  content: ' \25CF';
  margin-right: 0.5rem;
  color: #af4444;
}
</style>