<template>
  <b-container>
    <b-row>
      <b-col sm="12" :lg="$store.state.authDetails.access_token ? 10 : 12">
        <b-input-group :append="`${this.filteredSounds.length}/${this.sounds.length}`">
          <b-input placeholder="Search" v-model="filter" autofocus class="bg-dark text-light" />
        </b-input-group>
      </b-col>
      <b-col :sm="$store.state.authDetails.access_token ? 6 : 0" :lg="$store.state.authDetails.access_token ? 1 : 0">
        <b-button ref="playButton" class="float-right" variant="outline-light" style="width: 100%;"
          v-if="$store.state.authDetails.access_token"
          v-b-tooltip.hover title="random sound" @click="randomSound()">
          <b-icon :icon="diceIcon" :class="{ shake: animateDice}" />
        </b-button>
      </b-col>
      <b-col :sm="$store.state.authDetails.access_token ? 6 : 0" :lg="$store.state.authDetails.access_token ? 1 : 0">
        <b-button ref="playButton" class="float-right" variant="outline-light" style="width: 100%;"
          v-if="$store.state.authDetails.access_token"
          v-b-tooltip.hover title="stop playing" @click="stopSound()" >
          <b-icon icon="stop" />
        </b-button>
      </b-col>
    </b-row>
    <br>
    <b-card-group deck>
      <b-card v-for="sound in filteredSounds" :key="sound" style="min-width: 20rem; margin-bottom: 10px;" bg-variant="dark" text-variant="white">
        <b-card-text>
          {{sound}}
          <b-button ref="playButton" :data-key="sound" class="float-right" variant="outline-light"
            v-if="$store.state.authDetails.access_token" v-b-tooltip.hover title="play"
            @click="playSound(sound)" >
            <b-icon icon="play" />
          </b-button>
        </b-card-text>
      </b-card>
    </b-card-group>
  </b-container>
</template>

<script>
  export default {
    name: 'Sounds',
    data() {
      return {
        sounds: [],
        filter: '',
        diceIcon: 'dice1',
        animateDice: false
      }
    },
    methods: {
      playSound(sound) {
        this.$analytics.logEvent('play_sound', { sound });
        this.axios.get(`/sounds/play?sound=${sound}`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authDetails.access_token}`
          }
        }).catch((err) => this.handleError(err));
      },
      stopSound() {
        this.$analytics.logEvent('stop_sound');
        this.axios.get('/sounds/stop', {
          headers: {
            Authorization: `Bearer ${this.$store.state.authDetails.access_token}`
          }
        }).catch((err) => this.handleError(err));
      },
      randomSound() {
        this.animateDice = true;
        setTimeout(() => this.animateDice = false, 1000);
        this.diceIcon = `dice${Math.round(Math.random() * 6)}`
        const sound = this.sounds[Math.floor(Math.random() * this.sounds.length)];
        this.$analytics.logEvent('random_sound', { sound });
        this.axios.get(`/sounds/play?sound=${sound}`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authDetails.access_token}`
          }
        }).catch((err) => this.handleError(err));
      },
      handleError(err) {
        console.log(err.response.data);
        if (err.response.status === 400) {
          switch (err.response.data) {
            case 'no-perms':
              this.toast('Error', 'You\'re not permitted to play sounds.', 'danger');
              break;
            case 'no-voice-connection':
              this.toast('Error', 'Couldn\'t find you in a voice channel.', 'danger')
              break;
            case 'guild-not-found':
              this.toast('Error', 'Couldn\'t find your guild in database.', 'danger')
              break;
            case 'guild-not-prime':
              this.toast('Error', 'This feature requires the bots prime feature for this guild.', 'danger')
              break;
            case 'error-getting-member':
              this.toast('Error', 'There was an error getting your user. If it persists please contact support', 'danger')
              break;
            default:
              this.toast('Error', 'An unknown error occured.', 'danger');
              break;
          }
        }
        if (err.response.status === 401) {
          this.$store.commit('SET_TOKEN_EXPIRED');
        }
      }
    },
    mounted() {
      this.diceIcon = `dice${Math.round(Math.random() * 6)}`
      this.axios.get('sounds/list')
        .then((res) => {
          this.sounds = res.data.sounds;
        });
    },
    computed: {
      filteredSounds() {
        return this.sounds.filter((sound) => sound.toLowerCase().includes(this.filter.toLowerCase()));
      }
    }

  }
</script>

<style>

</style>
