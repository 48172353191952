<template>
  <b-container>
    <b-row><h1>List of available commands</h1></b-row>
    <b-row>
      <p>
        Commands with a * require the bots prime tier to work. to get it please join the bots discord server via the user menu and contact us there.
        <br>
        Some commands also require user, channel… IDs. If you don't know how to get those check out <a href="https://support.discord.com/hc/en-us/articles/206346498-Where-can-I-find-my-User-Server-Message-ID-" target="_blank" rel="noopener noreferrer">this guide</a>.
        <br>
        Command parameters surrounded with &lt;&gt; are required. arguments surrounded with [] are optional.
      </p>
    </b-row>
    <br>
    <b-row>
      <h2>Member Commands</h2>
      <b-table :items="memberCmds" text="" class="table"/>
    </b-row>
    <b-row>
      <h2>Team Commands</h2>
      <b-table :items="teamCmds" text="" class="table"/>
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Commands',
  components: {
  },
  data() {
    return {
      memberCmds: [
          { command: 'ping', description: 'sends you a message with the bots response time' },
          { command: 'coin', description: 'flips a coin' },
          { command: 'mcstatus <serverIp>', description: 'gets the status of a given minecraft server' },
          { command: 'xp', description: 'gets your current xp amount' },
          { command: 'lvl', description: 'gets your current level' },
          { command: 'code* <code snippet>', description: 'generates an image of your code snippet highlighted' },
          { command: 'duden* <searchTerm>', description: 'responds with a lexikon entry of the requested word (source: pons)' },
          { command: 'serverCount', description: 'see the amount of servers using this bot' },
          { command: 'uptime', description: 'gets the bots uptime' },
        ],
      teamCmds: [
          { command: 'setUserSound* <userID> <join/leave> <soundName>', description: 'sets the sound played when to user joins/leaves a channel' },
          { command: 'getUserSounds* <userID>', description: 'gets the sounds played when to user joins a channel' },
          { command: 'silentJoin*', description: 'disables join sound for the next join' },
          { command: 'silentLeave*', description: 'disables leave sound for the next leave' },
          { command: 'play* <soundName> [channelId]', description: 'playes a specific sound in your/the given channel' },
          { command: 'p* <soundName> [channelId]', description: 'playes a specific sound in your/the given channel' },
          { command: 'randomSound* [channelId]', description: 'playes a random sound in your/the given channel' },
          { command: 'rs* [channelId]', description: 'playes a random sound in your/the given channel' },
          { command: 'searchSound* <searchTerm>', description: 'searches the database for sounds' },
          { command: 'getLastSound*', description: 'gets the last sound played on the server' },
          { command: 'replaySound* [channelId]', description: 'replayes the last sound played on the server in your/a given channel' },
          { command: 'join*', description: 'joins your channel' },
          { command: 'leave*', description: 'leaves your channel' },
          { command: 'checkSound* <soundName>', description: 'get metadata (length,…) of a specific sound' },
          { command: 'setChannelSound* <soundName>', description: 'sets the sound played when a user joins for your current channel' },
          { command: 'automove <userId> <channelId> [true/false]', description: 'automatically moves a member on join to the given channel. true/false for persistance' },
          { command: 'antiafk <true/false>', description: 'always moves you back if you get moved to the afk channel' },
          { command: 'antijoin <userMention or userId> <true/false>', description: 'if enabled for a user it will prevent that user from joining any channels on the server' },
          { command: 'tts* <text>', description: 'plays a text to speech message in your voice channel' },
          { command: 'moveChannel <channelId>', description: 'moved all users in your voice channel to another one' },
          { command: 'setWelcomeChannel <channelId>', description: 'sets the channel to broadcast welcome messages' },
          { command: 'setMemberRoleOnJoin <true/false>', description: 'enables/disables adding users the member role if they join the server' },
          { command: 'purge <messageAmount>', description: 'deletes the given amount of messages in the channel' },
          { command: 'repeat <message>', description: 'send a message as the bot' },
          { command: 'translate* [languageCode]', description: 'translates the newest message' },
          { command: 'dmtranslate* [languageCode]', description: 'translates the newest message and returns the answer in a private message' },
          { command: 'createProjectCategory [categoryName]', description: 'initializes the project feature' },
          { command: 'createProjectArchive [categoryName]', description: 'creates a project-channel archive' },
          { command: 'createProject <projectName> <userMention> [userMention..]', description: 'creates a new private project channel' },
          { command: 'addMemberToProject <userId>', description: 'adds a member to the project channel you send the cmd in' },
          { command: 'removeMemberFromProject <userMention>', description: 'removes a member from the project channel you send the cmd in' },
          { command: 'deleteProject', description: 'deletes the project channel you send the cmd in' },
          { command: 'archiveProject', description: 'moves the project channel you send the cmd in to the archive. you won\'t be able to send any more messages' },
          { command: 'ccmds create <command>', description: 'starts the setup assistant for a new command' },
          { command: 'kick <userMention> <reason>', description: 'kicks a user' },
          { command: 'setMuteRole <roleName>', description: 'sets the muted role' },
          { command: 'mute <userMention> <reason>', description: 'mutes a user (removes all roles and adds muted role)' },
          { command: 'unmute <userMention> <reason>', description: 'unmutes a user and restores roles' },
          { command: 'warn <userMention> <reason>', description: 'warns a user' },
          { command: 'clearWarnings <userMention>', description: 'clears all warnings for a user' },
          { command: 'warnings <userMention>', description: 'shows all warnings for a user' },
          { command: 'ban <userMention> <reason>', description: 'bans a user' },
          { command: 'check <userMention or userId>', description: 'replies with detailed user information' },
          { command: 'checkDM <userMention or userId>', description: 'dm\'s you detailed user information' },
          { command: 'roleSettings createChannel', description: 'saves the current channel as new role settings channel (be aware this will clear the channel)' },
          { command: 'roleSettings deleteChannel', description: 'removes the role settings channel the command was sent in' },
          { command: 'roleSettings setMessage <message>', description: 'set\'s the message for the role settings channel the command was sent in' },
          { command: 'roleSettings create', description: 'creates a new role setting in the role settings channel the command was sent in' },
          { command: 'roleSettings remove', description: 'removes a role setting in the role settings channel the command was sent in' },
          { command: 'roleSettings updateChannel', description: 'clears all messages and resends the settings message in the role settings channel the command was sent in' },
          { command: 'autoMod setLogChannel', description: 'set\'s the channel where deleted messages are reposted for mods to review to the channel the command was sent in' },
          { command: 'autoMod setMuteChannel', description: 'set\'s the channel where muted users will still be allowed to post messages' },
          { command: 'autoMod add <regex string>', description: 'adds a new rule which will delete messages on match' },
          { command: 'autoMod rules', description: 'show\'s all the rules configured' },
          { command: 'autoMod remove <index>', description: 'removes a rule by the index shown in the rules list' },
          { command: 'autoMod autoWarn <true/warn>', description: 'weather the bot should automatically warn users if automod deletes one of their messages' },
          { command: 'autoMod autoMute <numberOfWarnings>', description: 'if the user\'s warning count is higher than the set amount he will automaticall get muted on a automod event' },
          { command: 'autoMod autoPerms <true/warn>', description: 'weather the bot should automatically manage all the channels permissions to deny muted members from chatting' },
          { command: 'autoMod autoSpam* <true/warn>', description: 'detects and deletes spam messages and muted the member' },
          { command: 'getRoleMemberCount <roleId>', description: 'replies with the number of members that have the specified role' },
          { command: 'resetLevel', description: 'removes all level / xp data of this server from the database' },
          { command: 'setLang <de/en>', description: 'sets the language for bot messages in your server' },
          { command: 'publishAll', description: 'publishes all messages in the channel' },
          { command: 'setAutoPublish <true/false>', description: 'if enabled in a news channel all new messages will be published automatically' },
          { command: 'permstest', description: 'replies with true or false depending if you have access to team commands' },
          { command: 'trollRepeat <userMention> <true/false>', description: 'if enabled all messages the user sends will be repeated by the bot' },
          { command: 'voting <description> <one option per new line>', description: 'creates a quick voting message to decide between multiple options' },
        ]
    };
  },
  methods: {},
  mounted() {
  }
}
</script>

<style scoped>
table {
  color: white;
}
</style>