<template>
  <div>
    <b-button href="https://discord.com/oauth2/authorize?client_id=363395679084871681&scope=bot&permissions=2146958711" target="_blank">Invite the Bot</b-button>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>
