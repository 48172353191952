<template>
  <b-container>
    <p class="loginInfo">
      <b-button @click="triggerLogin">Login with Discord</b-button>
    </p>
  </b-container>
</template>

<script>
export default {
  name: 'Login',
  methods: {
    triggerLogin() {
      this.$store.dispatch('getAuthURL');
      this.$analytics.logEvent('get_auth_url');
    },
  },
}
</script>

<style>
.loginInfo {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: xx-large;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>