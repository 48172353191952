<template>
  <b-container fluid>
    <b-row class="guildInfoWrapper">
      <b-col cols="12" class="guildInfo">
        <b-row>
          <b-col><h2>Guild Info</h2></b-col>
        </b-row>
        <b-skeleton-wrapper :loading="!guildInfo">
        <template #loading>
          <div>
            <b-row
            v-for="n in 15"
            :key="n"
            >
              <b-col cols="12"><b-skeleton width="100%"><p></p></b-skeleton></b-col>
            </b-row>
          </div>
        </template>

        <div v-if="guildInfo">
          <b-row>
            <b-col cols="5"><b>Lang</b></b-col>
            <b-col cols="7">{{guildInfo.lang}}</b-col>
          </b-row>
          <b-row>
            <b-col cols="5"><b>Prime</b></b-col>
            <b-col cols="7">{{guildInfo.prime ? 'yes' : 'no'}}</b-col>
          </b-row>
          <b-row>
            <b-col cols="5"><b>Last Sound</b></b-col>
            <b-col cols="7">{{guildInfo.lastPlayedSound}}</b-col>
          </b-row>
          <b-row>
            <b-col cols="5"><b>Member on Join</b></b-col>
            <b-col cols="7">{{guildInfo.setMemberOnJoin ? 'yes' : 'no'}}</b-col>
          </b-row>
          <b-row>
            <b-col cols="5"><b>Member Prefix</b></b-col>
            <b-col cols="7">{{guildInfo.memberCommandPrefix}}</b-col>
          </b-row>
          <b-row>
            <b-col cols="5"><b>Team Prefix</b></b-col>
            <b-col cols="7">{{guildInfo.teamCommandPrefix}}</b-col>
          </b-row>
          <b-row>
            <b-col cols="5"><b>Owner Prefix</b></b-col>
            <b-col cols="7">{{guildInfo.ownerCommandPrefix}}</b-col>
          </b-row>
        </div>

      </b-skeleton-wrapper>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  // @ is an alias to /src
  /*
  id:"757335944545435798"
  name:"craftycram's development server"
  owner:true
  permissions:"137438953471"
  */
  export default {
    name: 'GuildInfo',
    data() {
      return {
        guildInfo: undefined,
      };
    },
    props: {
      guild: Object,
    },
    mounted() {
      this.getGuildInfo();
    },
    methods: {
      getGuildInfo() {
        this.axios.get(`/guild/getGuildInfo?guild=${this.guild.id}`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.authDetails.access_token}`
        }
      }).then((res) => this.guildInfo = res.data).catch((err) => {
          if (err.response.status === 401) {
            this.$store.commit('SET_TOKEN_EXPIRED');
          }
        });
      }
    },
    computed: {},
    watch: {
      guild() {
        if (this.guild) this.getGuildInfo();
      }
    }
  }
</script>

<style scoped>
  .guildInfo {
    padding: 2rem;
    border-radius: 1rem;
    background-color: #2d2f33;
    margin-bottom: 1rem;
  }
</style>