<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" class="leaderboard">

        <b-row>
          <b-col><h2>Leaderboard</h2></b-col>
        </b-row>
        <b-row>
          <b-col cols="2"><p><b>Pos</b></p></b-col>
          <b-col cols="6"><p><b>User</b></p></b-col>
          <b-col cols="2"><p><b>Level</b></p></b-col>
          <b-col cols="2"><p><b>XP</b></p></b-col>
        </b-row>
        <b-skeleton-wrapper :loading="!leaderboard">
        <template #loading>
          <div>
            <b-row
            v-for="n in 15"
            :key="n"
            >
              <b-col cols="12"><b-skeleton width="100%"><p></p></b-skeleton></b-col>
            </b-row>
          </div>
        </template>

        <div>
          <b-row
          v-for="(u, i) in leaderboard"
          :key="u.id"
          :style="{ fontWeight: u.id === $store.state.userDetails.user.id ? 'bold' : 'normal', color: u.id === $store.state.userDetails.user.id ? 'white' : 'inherit' }"
          >
            <b-col cols="2"><p>{{i+1}}</p></b-col>
            <b-col cols="6"><p>{{u.displayName || 'name unknown'}}</p></b-col>
            <b-col cols="2"><p>{{u.level || 0}}</p></b-col>
            <b-col cols="2"><p>{{u.xp || 0}}</p></b-col>
          </b-row>
        </div>
      </b-skeleton-wrapper>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  // @ is an alias to /src
  /*
  id:"757335944545435798"
  name:"craftycram's development server"
  owner:true
  permissions:"137438953471"
  */
  export default {
    name: 'Leaderboard',
    data() {
      return {
        leaderboard: undefined,
      };
    },
    props: {
      guild: Object,
    },
    mounted() {
      this.getLeaderboard();
    },
    methods: {
      getLeaderboard() {
        this.axios.get(`/guild/getLeaderboard?guild=${this.guild.id}`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.authDetails.access_token}`
        }
      }).then((res) => this.leaderboard = res.data).catch((err) => {
          if (err.response.status === 401) {
            this.$store.commit('SET_TOKEN_EXPIRED');
          }
        });
      }
    },
    computed: {},
    watch: {
      guild() {
        if (this.guild) this.getLeaderboard();
      }
    }
  }
</script>

<style scoped>
  .leaderboard {
    padding: 2rem;
    border-radius: 1rem;
    background-color: #2d2f33;
    margin-bottom: 1rem;
  }
</style>