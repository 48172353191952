<template>
  <b-container>
    <b-row>
      <b-col cols="11">
        <h1>Your Guilds</h1>
      </b-col>
      <b-col cols="1">
        <svg v-b-tooltip.hover :style="{ color: filterOwned ? '#faa61a' : '#91959c' }" :class="{ guildCrown: true, shake: animateOwned }" ref="crownSelector" @click="toggleOwned" title="only show servers you own"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.6572 5.42868C13.8879 5.29002 14.1806 5.30402 14.3973 5.46468C14.6133 5.62602 14.7119 5.90068 14.6473 6.16202L13.3139 11.4954C13.2393 11.7927 12.9726 12.0007 12.6666 12.0007H3.33325C3.02725 12.0007 2.76058 11.792 2.68592 11.4954L1.35258 6.16202C1.28792 5.90068 1.38658 5.62602 1.60258 5.46468C1.81992 5.30468 2.11192 5.29068 2.34325 5.42868L5.13192 7.10202L7.44592 3.63068C7.46173 3.60697 7.48377 3.5913 7.50588 3.57559C7.5192 3.56612 7.53255 3.55663 7.54458 3.54535L6.90258 2.90268C6.77325 2.77335 6.77325 2.56068 6.90258 2.43135L7.76458 1.56935C7.89392 1.44002 8.10658 1.44002 8.23592 1.56935L9.09792 2.43135C9.22725 2.56068 9.22725 2.77335 9.09792 2.90268L8.45592 3.54535C8.46794 3.55686 8.48154 3.56651 8.49516 3.57618C8.51703 3.5917 8.53897 3.60727 8.55458 3.63068L10.8686 7.10202L13.6572 5.42868ZM2.66667 12.6673H13.3333V14.0007H2.66667V12.6673Z" fill="currentColor" aria-hidden="true"></path></svg>
      </b-col>
    </b-row>
    <b-row
    v-for="g in userGuilds"
    :key="g.id"
    class="guild d-flex align-items-center">
      <b-col cols="2" sm="2" md="2" lg="1">
        <div class="guildIconContainer">
          <img :src="g.icon ? `https://cdn.discordapp.com/icons/${g.id}/${g.icon}.png` : 'https://discord.com/assets/9f6f9cd156ce35e2d94c0e62e3eff462.png'" alt="" class="guildIcon" srcset="">
        </div>
      </b-col>
      <b-col cols="1" sm="1" md="1" lg="1">
        <div class="guildIconContainer">
          <img src="https://www.nicepng.com/png/full/46-464368_owner-discord-emoji-discord.png" v-if="g.owner==='1'" class="guildCrown" alt="">
          <svg v-b-tooltip.hover title="you own this server" v-if="g.owner" class="guildCrown"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.6572 5.42868C13.8879 5.29002 14.1806 5.30402 14.3973 5.46468C14.6133 5.62602 14.7119 5.90068 14.6473 6.16202L13.3139 11.4954C13.2393 11.7927 12.9726 12.0007 12.6666 12.0007H3.33325C3.02725 12.0007 2.76058 11.792 2.68592 11.4954L1.35258 6.16202C1.28792 5.90068 1.38658 5.62602 1.60258 5.46468C1.81992 5.30468 2.11192 5.29068 2.34325 5.42868L5.13192 7.10202L7.44592 3.63068C7.46173 3.60697 7.48377 3.5913 7.50588 3.57559C7.5192 3.56612 7.53255 3.55663 7.54458 3.54535L6.90258 2.90268C6.77325 2.77335 6.77325 2.56068 6.90258 2.43135L7.76458 1.56935C7.89392 1.44002 8.10658 1.44002 8.23592 1.56935L9.09792 2.43135C9.22725 2.56068 9.22725 2.77335 9.09792 2.90268L8.45592 3.54535C8.46794 3.55686 8.48154 3.56651 8.49516 3.57618C8.51703 3.5917 8.53897 3.60727 8.55458 3.63068L10.8686 7.10202L13.6572 5.42868ZM2.66667 12.6673H13.3333V14.0007H2.66667V12.6673Z" fill="currentColor" aria-hidden="true"></path></svg>
        </div>
      </b-col>
      <b-col cols="7" sm="7" md="6" lg="8">
        <p class="guildName" style="margin: 0">{{g.name}}</p>
      </b-col>
      <b-col cols="2" sm="2" md="3" lg="2">
        <b-button
          class="guildButton"
          v-if="$store.state.botGuilds.includes(g.id)"
          @click="selectGuild(g)"
        >
          <b-icon icon="gear"/>
          <span class="d-none d-md-inline"> Manage</span>
        </b-button>
        <b-button
          class="guildButton"
          variant="outline-light"
          v-if="!$store.state.botGuilds.includes(g.id)"
          :href="`https://discord.com/oauth2/authorize?client_id=363395679084871681&guild_id=${g.id}&scope=bot&permissions=2146958711`"
          target="_blank"
        >
          <b-icon icon="plus-circle"/>
          <span class="d-none d-md-inline"> Invite Bot</span>
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src
/*
id:"757335944545435798"
name:"craftycram's development server"
owner:true
permissions:"137438953471"
*/
export default {
  name: 'Guilds',
  data() {
    return {
      filterOwned: false,
      animateOwned: false,
    };
  },
  mounted() {
    this.$analytics.logEvent('guild_overview');
  },
  components: {
  },
  methods: {
    selectGuild(g) {
      this.$analytics.logEvent('select_guild');
      this.$router.push({
        path: '/manage',
        query: {
          guild: g.id,
        }
      });
    },
    toggleOwned() {
      this.$analytics.logEvent('toggle_owned');
      this.filterOwned = !this.filterOwned;
      this.animateOwned = true;
      setTimeout(() => this.animateOwned = false, 1000);
    }
  },
  computed: {
    userGuilds() {
      const userGuilds = this.filterOwned ? this.$store.state.userGuilds.filter((g) => g.owner) : this.$store.state.userGuilds;
      // temp.sort((a) => !botGuilds.includes(a.id))
      // temp.sort((a) => !botGuilds.includes(a.id));
      const botGuilds = this.$store.state.botGuilds;
      const managable = userGuilds.filter((e) => botGuilds.includes(e.id));
      const nonManagable = userGuilds.filter((e) => !botGuilds.includes(e.id));
      managable.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase());
      nonManagable.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase());
      const joined = [...managable, ...nonManagable];
      return joined;
    }
  }
}
</script>

<style scoped>
.guild {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
  /*height: 5rem;*/
  background-color: #2d2f33;
}
.guild:nth-child() {
  background-color: #000000;
}
.guildIconContainer {
  position: relative;
}
.guildIcon {
  display: block;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}
.guildCrown {
  /*margin-top: 1.5rem;*/
  color: #faa61a;
  width: 1rem;
  height: 1rem;
}
.guildName {
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
}
.guildButton {
  /*margin-top: 0.75rem;*/
  height: 2.5rem;
}
</style>