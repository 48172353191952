<template>
  <b-container>
    <b-row>
      <h1>Server Log</h1>
    </b-row>
    <b-row v-for="e in log" :key="e._id" class="logentry d-flex align-items-center">
      <b-col cols="2" sm="2" md="2" class="">
        <img :src="e.memberAvatar ? `https://cdn.discordapp.com/avatars/${e.memberId}/${e.memberAvatar}.webp?size=32` : `https://cdn.discordapp.com/embed/avatars/${e.memberName.split('#')[1]%5}.png?size=32`" class="avatar">
      </b-col>
      <b-col cols="10" sm="10" md="2" class="overflow">
        <b v-b-tooltip.hover="e.memberName">{{e.memberName}}</b>
        <span v-if="e.autoMod"><br><small>AutoMod Action</small></span>
        <span v-if="!e.autoMod"><br><small>User Command</small></span>
      </b-col>
      <b-col cols="12" sm="12" md="2" class="overflow">
        <span v-if="!e.autoMod" v-b-tooltip.hover="e.command">{{e.command}}</span>
        <span v-if="e.autoMod" v-b-tooltip.hover="e.autoModAction">{{e.autoModAction}}</span>
      </b-col>
      <b-col cols="12" sm="12" :md="e.autoMod ? 5 : 6" class="overflow">
        <span v-if="!e.autoMod" v-b-tooltip.hover="e.message">{{e.message}}</span>
        <span v-if="e.autoMod">
          <span v-if="e.autoModRule" v-b-tooltip.hover="e.autoModRule"><b>Rule</b> {{e.autoModRule}}<br></span>
          <span v-b-tooltip.hover="e.autoModFlag" v-if="e.autoModAction !== 'autoSpam'"><b>Flagged Content</b> {{e.autoModFlag}}</span>
          <span v-b-tooltip.hover="JSON.parse(e.autoModFlag)[0].content" v-if="e.autoModAction === 'autoSpam'">{{JSON.parse(e.autoModFlag)[0].content}}</span>
        </span>
      </b-col>
      <b-col v-if="e.autoModAction === 'autoSpam'" cols="12" sm="12" md="1" class="overflow">
        <b-icon icon="info-circle" v-b-tooltip.hover="'Show Details'" style="cursor: pointer;" @click="showDetails(e)"/>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-center pageButtonWrapper">
      <p class="pageButton" @click="previous">
        <b-icon icon="chevron-left"/>
        Previous
      </p>
      <p style="padding: 0 1rem;">
        {{page + 1}}
      </p>
      <p class="pageButton" @click="next">
        Next
        <b-icon icon="chevron-right"/>
      </p>
    </b-row>

    <b-modal
      id="modal-autoSpam"
      title="AutoMod Details"
      header-bg-variant="dark"
      header-text-variant="light"
      body-bg-variant="dark"
      body-text-variant="light"
      hide-footer
      footer-bg-variant="dark"
      footer-text-variant="light"
    >
      <b>Message Content</b>
      <p>{{details[0].content}}</p>
      <b>Channels</b>
      <div>
        <p v-for="d in details" :key="d._id">
          #{{d.channelName || d.channelId}}
          at
          {{getDate(d.ts)}}
          <br>
        </p>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
  export default {
    name: 'ServerLog',
    data() {
      return {
        log: null,
        details: [{}],
        page: 0,
        steps: 10,
      };
    },
    components: {
    },
    created() {
      /*
      if (!this.$route.params.guild) return this.$router.push('/guilds').then(() => this.toast('Error - No Guild',
        'Please select a Guild to manage', 'danger'));
      */
      if (!this.$route.query.guild) return this.$router.push('/guilds').then(() => this.toast('Error - No Guild',
        'Please select a Guild to manage', 'danger'));
    },
    mounted() {
      this.$analytics.logEvent('serverlog', {guild: this.guild});
      this.getServerLog();
    },
    methods: {
      getServerLog() {
        this.axios.get(`/guild/getServerLog?guild=${this.$route.query.guild}&skip=${this.steps * this.page}&limit=${this.steps}`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.authDetails.access_token}`
        }
      }).then((res) => this.log = res.data).catch((err) => {
          if (err.response.status === 401) {
            this.$router.push('/').then(() => this.toast('Error', 'You\'re not permitted to view this page.', 'danger'));
          }
        });
      },
      showDetails(e) {
        this.details = JSON.parse(e.autoModFlag);
        this.$bvModal.show('modal-autoSpam');
      },
      getDate(ts) {
        const date = new Date(ts);
        return date.toLocaleString();
      },
      previous() {
        if (this.page < 1) return; 
        this.page -= 1;
        this.getServerLog();
      },
      next() {
        this.page += 1;
        this.getServerLog();
      },
    },
    computed: {
    }
  }
</script>

<style scoped>

.logentry {
  background-color: #2d2f33;
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
  /*height: 5rem;*/
  /*line-height: 3rem;*/
}

.avatar {
  width: 3rem;
  height: 3rem;
}

.overflow {
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
}

.pageButtonWrapper {
  padding: 1rem 0;
}

.pageButton {
  cursor: pointer;
}
</style>