<template>
  <div class="callbackInfo">
    <div v-show="$store.state.errorOnTokens===false">
      <h1>LoggedIn successfully.</h1>
      <h2>This window will close soon. You can also close it manually</h2>
    </div>
    <div v-show="$store.state.errorOnTokens===true">
      <h1>Something went wrong</h1>
      <h2>There was an error logging you in.</h2>
      <p>Please check the console for further information or contact us for help.</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Callback',
  components: {
  },
  data() {
    return {
      success: false,
    };
  },
  mounted() {
    this.$analytics.logEvent('code_callback');
    if (this.$store.state.errorOnTokens) return;
    if (this.$router.currentRoute.query.code) {
      console.log('test3');
      console.log(this.$route.query.code);
      this.$store.dispatch('getTokens', this.$route.query.code);
    }
  }
}
</script>

<style scoped>
.callbackInfo {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: xx-large;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>