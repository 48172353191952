<template>
  <b-container>
    <b-row v-if="!guild"><h1>loading data…</h1></b-row>
    <b-row v-if="guild"><h1>{{guild.name}}</h1></b-row>
    <b-row>
      <b-col cols="12" lg="6" class="">
        <Leaderboard :guild="guild"/>
      </b-col>
      <b-col cols="12" lg="6" class="">
        <TeamMenu v-if="perms.isTeam" :guild="guild"/>
        <GuildInfo :guild="guild"/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  // @ is an alias to /src
  /*
  id:"757335944545435798"
  name:"craftycram's development server"
  owner:true
  permissions:"137438953471"
  */
import Leaderboard from '@/components/Leaderboard';
import GuildInfo from '@/components/GuildInfo';
import TeamMenu from '@/components/TeamMenu';
  export default {
    name: 'Manage',
    data() {
      return {
        perms: null,
      };
    },
    components: {
      Leaderboard,
      GuildInfo,
      TeamMenu,
    },
    created() {
      if (!this.$route.query.guild) return this.$router.push('/guilds').then(() => this.toast('Error - No Guild',
        'Please select a Guild to manage', 'danger'));
    },
    mounted() {
      this.$analytics.logEvent('manage', {guild: this.guild});
      this.getIsTeam();
    },
    methods: {
      getIsTeam() {
        this.axios.get(`/guild/isTeam?guild=${this.$route.query.guild}`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.authDetails.access_token}`
        }
      }).then((res) => this.perms = res.data).catch((err) => {
          if (err.response.status === 401) {
            this.$store.commit('SET_TOKEN_EXPIRED');
          }
        });
      }
    },
    computed: {
      guild() {
        return this.$store.state.userGuilds.find((g) => g.id === this.$route.query.guild);
      },
    }
  }
</script>

<style scoped>
  .leaderboard {
    padding: 2rem;
    border-radius: 1rem;
    background-color: #2d2f33;
  }
</style>