<template>
  <b-container fluid>
    <b-row class="teamMenuWrapper">
      <b-col cols="12" class="teamMenu">
        <b-row>
          <b-col><h2>Team Menu</h2></b-col>
        </b-row>

        <div>
          <b-row>
            <b-col>
              <b-button @click="serverLog">Show Server-Log</b-button>
            </b-col>
          </b-row>
        </div>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'TeamMenu',
    data() {
      return {
      };
    },
    props: {
      guild: Object,
    },
    mounted() {
    },
    methods: {
      serverLog() {
        this.$router.push({
          // name: 'ServerLog',
          path: '/serverlog',
          // params: {
          query: {  
            guild: this.$route.query.guild,
          }
        });
      }
    },
    computed: {},
    watch: {
    }
  }
</script>

<style scoped>
  .teamMenu {
    padding: 2rem;
    border-radius: 1rem;
    background-color: #2d2f33;
    margin-bottom: 1rem;
  }
</style>